<template>
  <div>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="1200px"
      transition="slide-y-transition"
    >
      <v-card class="dialog-card-border">
        <v-card-title class="text-h6 font-weight-light">
          {{ $t('Residence Data') }} [{{ fullName }}]
          <v-spacer />
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon class="mdil-36px mdil-rotate-45">
              mdil-plus
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- parent form -->
            <v-col cols="12">
              <validation-observer
                ref="parentForm"
                v-slot="{ handleSubmit, reset }"
              >
                <form
                  autocomplete="off"
                  @submit.prevent="handleSubmit(updateResidence)"
                  @reset.prevent="reset"
                >
                  <v-row no-gutters>
                    <v-col
                      class="px-1"
                      cols="12"
                      sm
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Full Name')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.full_name"
                          :label="`* ${$t('Full Name')}`"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      class="px-1"
                      cols="12"
                      sm
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Nationality')"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.nationality"
                          :label="`* ${$t('Nationality')}`"
                          outlined
                          dense
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="auto"
                    >
                      <v-btn
                        color="primary"
                        small
                        depressed
                        type="submit"
                      >
                        <v-icon left>
                          mdi-check
                        </v-icon>
                        {{ $t('Save') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </form>
              </validation-observer>
            </v-col>

            <v-col cols="12">
              <v-divider />
            </v-col>

            <!-- child data & form -->
            <v-col cols="12">
              <v-row>
                <!-- child data -->
                <v-col
                  cols="12"
                  lg="9"
                >
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th style="width: 125px">
                            {{ $t('Created At') }}
                          </th>
                          <th>{{ $t('Residence Date') }}</th>
                          <th>{{ $t('Expire Date') }}</th>
                          <th>{{ $t('Profile picture') }}</th>
                          <th>{{ $t('Residence picture') }}</th>
                          <th />
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(row, index) in data.details"
                          :key="index"
                        >
                          <td>{{ index + 1 }}</td>
                          <td>{{ $_date_time_format(row.created_at) }}</td>
                          <td>{{ row.residence_date }}</td>
                          <td>{{ row.expire_date }}</td>
                          <td class="py-1">
                            <a
                              :href="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                              target="_blank"
                            >
                              <div style="width: 50px; height: 50px">
                                <img
                                  :src="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                                  style="width: 100%; height: 100%; object-fit: cover"
                                  class="rounded"
                                  alt=""
                                >
                              </div>
                            </a>
                          </td>
                          <td class="py-1">
                            <a
                              :href="`${$_file_path()}${row.residence_picture}?token=${$_auth_token()}`"
                              target="_blank"
                            >
                              <div style="width: 50px; height: 50px">
                                <img
                                  :src="`${$_file_path()}${row.residence_picture}?token=${$_auth_token()}`"
                                  style="width: 100%; height: 100%; object-fit: cover"
                                  class="rounded"
                                  alt=""
                                >
                              </div>
                            </a>
                          </td>
                          <td>
                            <div class="d-flex">
                              <v-btn
                                color="info"
                                small
                                text
                                depressed
                                @click="updateDetail(row)"
                              >
                                {{ $t('Edit') }}
                              </v-btn>

                              <v-btn
                                color="error"
                                small
                                text
                                depressed
                                @click="deleteDetail(row)"
                              >
                                {{ $t('Delete') }}
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <!-- child form -->
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  lg
                >
                  <div class="text-h6 ps-2 pb-3">
                    {{ editMode ? $t('Edit') : $t('Add New') }}
                  </div>

                  <validation-observer
                    ref="childFormRef"
                    v-slot="{ handleSubmit, reset }"
                  >
                    <form
                      autocomplete="off"
                      @submit.prevent="handleSubmit(submitChild)"
                      @reset.prevent="reset"
                    >
                      <v-row no-gutters>
                        <v-col
                          class="px-1"
                          cols="12"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('Residence Date')"
                            rules="required"
                          >
                            <app-date-picker
                              v-model="childForm.residence_date"
                              :label="$t('Residence Date')"
                              no-title
                              :error-messages="errors"
                            />
                          </validation-provider>
                        </v-col>

                        <v-col
                          class="px-1"
                          cols="12"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('Expire Date')"
                            rules="required"
                          >
                            <app-date-picker
                              v-model="childForm.expire_date"
                              :label="$t('Expire Date')"
                              no-title
                              :error-messages="errors"
                            />
                          </validation-provider>
                        </v-col>

                        <v-col
                          cols="12"
                          class="mb-4"
                        >
                          <app-image-uploader
                            v-if="showImages"
                            width="100%"
                            height="140px"
                            label="Profile picture"
                            :parent-image-url="childForm.profile_picture"
                            @image-selected="childForm.profile_picture = $event"
                            @is-image-updated="childForm.profile_image_updated = $event"
                          />

                          <div
                            v-else
                            style="width: 100%; height: 140px; border: 1px dashed #ccc;"
                            class="rounded-lg"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="mb-4"
                        >
                          <app-image-uploader
                            v-if="showImages"
                            width="100%"
                            height="140px"
                            label="Residence picture"
                            :parent-image-url="childForm.residence_picture"
                            @image-selected="childForm.residence_picture = $event"
                            @is-image-updated="childForm.residence_image_updated = $event"
                          />

                          <div
                            v-else
                            style="width: 100%; height: 140px; border: 1px dashed #ccc;"
                            class="rounded-lg"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          class="text-end d-flex"
                        >
                          <v-btn
                            depressed
                            outlined
                            small
                            @click="cancelChildForm()"
                          >
                            {{ $t('Cancel') }}
                          </v-btn>

                          <v-spacer />

                          <v-btn
                            type="submit"
                            color="primary"
                            depressed
                            small
                          >
                            <v-icon left>
                              mdil-check
                            </v-icon>
                            <span class="pe-2">
                              {{ editMode ? $t('Save') : $t('Add') }}
                            </span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </form>
                  </validation-observer>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="py-3">
          <v-spacer />

          <v-btn
            depressed
            outlined
            small
            @click="closeDialog()"
          >
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    showDialog: { type: Boolean, default: false },
    id: { type: Number, required: true },
  },

  data() {
    return {
      data: {
        full_name: null,
        nationality: null,
        details: []
      },
      childForm: {
        residence_date: null,
        expire_date: null,
        profile_picture: null,
        residence_picture: null,
      },
      fullName: null,
      editMode: false,
      showImages: false,
    }
  },

  watch: {
    showDialog() {
      if (this.showDialog) {
        this.showImages = true
        this.getData()
      }
    },
  },

  methods: {
    getData() {
      axios.get(`factory/residence/${this.id}/`).then(res => {
        this.data = res.data
        this.fullName = this.data.full_name
      })
    },

    closeDialog() {
      this.$refs.parentForm.reset()
      this.$refs.childFormRef.reset()
      this.$emit('close')
    },

    async cancelChildForm() {
      this.showImages = false
      this.editMode = false
      this.childForm = {
        residence_date: null,
        expire_date: null,
        profile_picture: null,
        residence_picture: null,
      }
      await this.$_sleep(100)
      this.showImages = true
      this.$refs.childFormRef.reset()
    },

    updateResidence() {
      axios.put(`factory/residence/${this.data.id}/`, this.data).then(() => {
        this.$_notify('Saved successfully')
        this.getData()
      })
    },

    async updateDetail(row) {
      this.showImages = false
      this.editMode = true
      this.childForm = { ...row }
      await this.$_sleep(100)
      this.showImages = true
    },

    deleteDetail(row) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(`factory/residence/${this.data.id}/delete-detail/${row.id}/`).then(() => {
            this.$_notify('Deleted successfully')
            this.getData()
          })
        }
      })
    },

    submitChild() {
      let fd = new FormData()
      for (let key in this.childForm) {
        fd.append(key, this.childForm[key])
      }

      if (this.editMode) {
        axios.put(`factory/residence/${this.data.id}/update-detail/${this.childForm.id}/`, fd).then(() => {
          this.$_notify('Saved successfully')
          this.getData()
          this.cancelChildForm()()
        })
      } else {
        axios.post(`factory/residence/${this.data.id}/create-detail/`, fd).then(() => {
          this.$_notify('Added successfully')
          this.getData()
          this.cancelChildForm()()
        })
      }
    },
  },
}
</script>
